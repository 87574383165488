import Style from "./output.module.css";
import { filteredResulttype, submitScenarioType, tabType } from "../../../@types/user";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import NumberFormat from "../../../utils/NumberFormat";

import { AttachMoneyOutlined, CurrencyRupee, Percent } from "@mui/icons-material";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ScrollerWrapper from "../../scrollerWrapper";
import { Axios } from "../../../Axios";
import CurrencyIconRenderer from "../../CurrencyIconRendered";
import { useDispatch } from "react-redux";
import { getData } from "../../../redux/calculatorSlice";

type propType = {
    myresult: filteredResulttype[],
    prep:{
        name:string,
        data:{
            name:string,
            value:string[]
        }[],
        servicableYear:number,
        yearsRemaining:number
    }[][],
    setPrep:(a:any)=> void,
    submitableScenario:submitScenarioType[]
}

const DeltaTableScreen = ({myresult,prep,setPrep,submitableScenario}:propType)=>{

    const user = useSelector((state: RootState) => state.user.user);
    const dispatch = useDispatch();
    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "USD");
    const [alteredField,setAlteredField] = useState({
        outer:0,
        inner:0,
        initial:0
    })
    const [loading,setLoading] = useState(true);
      
    const [result,setResult] = useState<{
        name:string,
        data:{
            name:string,
            value:number[]
        }[],
        servicableYear:number
    }[][]>([]);

    const [myTimeout,setMyTimeout] = useState<any>();


    useEffect(()=>{
        if(prep.length < 1) return;
        console.log("prep",prep);
        console.log("final result",myresult);
        clearTimeout(myTimeout);
        setMyTimeout(setTimeout(calcOverCloud,600));

    },[prep])


    const calcOverCloud = ()=>{

        let alt;
        let select;

        if(alteredField.initial === 0){
            alt = prep[alteredField.outer].map(elem=> (elem.data.map(el=> el.value)) );
            select = myresult[0];
        }
        else{
            alt = prep[alteredField.outer].filter(elem=> elem.name === prep[alteredField.outer][alteredField.inner].name ).map(elem=> (elem.data.map(el=> el.value)) );
            select = myresult[0].filter(elem=> elem.name === prep[alteredField.outer][alteredField.inner].name );
        }
        
        setLoading(true);

        console.log("select",select);
        let pre = dispatch(getData(""));
        let data = JSON.parse(pre.payload);
        let calc:tabType = data.tabs;

        Axios.post(`/api/v1/delta-cashflow`,{
            select : select,
            head : alt,
            version : data.tabs[data.activeTab].version || "v2"
        },{
            headers:{
                Authorization: user?.token
            }
        })
        .then(data=>{
            let res = data.data.data;
            setLoading(false);

            console.log("Delta Table Result",res.result);

            if(alteredField.initial === 0){
                setResult(res.result);
            }
            else{
                let x:{
                    name:string,
                    data:{
                        name:string,
                        value:number[]
                    }[],
                    servicableYear:number
                }[][] = [...result];

                x[alteredField.outer][alteredField.inner] = res.result[0][0];

                setResult(x);

            }

            setAlteredField(prev=> ({...prev,initial:prev.initial+1}));
        })
        .catch(e=>{
            setLoading(false);
        })

    }


    const changeHandler = (aindex:number,tableInd:number,rowInd:number,cellInd:number,val:string)=>{

        let x = [...prep];

        x[aindex][tableInd].data[rowInd].value[cellInd] = val;

        setAlteredField(prev=> ({...prev,outer:aindex,inner:tableInd}) );
        setPrep(x);

    }

    return(
        <div className="chartBoxCnt printable">

            {
                loading &&
                <div style={{
                    position:"fixed",
                    top:0,
                    left:0,
                    background:"rgba(255,255,255,.8)",
                    zIndex:10,
                    height:"100vh",
                    width:"100vw",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}>
                    <p style={{
                        fontSize:"42px"
                    }}>Calculating...</p>
    
                </div>
            }
            
                {
                    prep.map((aelem,aindex)=>(
          
                        aelem.map((elem,index)=>(
                            <div className={Style.tableCnt} key={`sasa${index}`}>
                                    <h2 className={Style.tableName}>{elem.name}</h2>
                                    <div className={Style.highlightCnt}>

                                        <div className={Style.highlightChild}>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    Enterprise NPV
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${result[aindex] && result[aindex][index].data[result[aindex][index].data.length-4].value[0] < 0 && "danger"}
                                                `}>
                                                <>
                                                <CurrencyIconRenderer type={myCurrency} />
                                                </>
                                                    {
                                                        result[aindex] &&
                                                        <>
                                                        {
                                                            (myCurrency === "INR") ?
                                                            (NumberFormat.toINDstandard(result[aindex][index].data[result[aindex][index].data.length-4].value[0].toFixed(2)) || 0)
                                                            :
                                                            (NumberFormat.toUSstandard(result[aindex][index].data[result[aindex][index].data.length-4].value[0].toFixed(2)) || 0 )
                                                        }
                                                    
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    Enterprise IRR
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${(result[aindex] && result[aindex][index].data[result[aindex][index].data.length-3].value[0] < ((myresult[aindex][index].cashFlow.map(elem=> elem.irrRate).reduce((a,b)=> a+b) || 0)/myresult[aindex][index].cashFlow.length) ) ? "danger" : "green"}
                                                `}>
                                                {
                                                    result[aindex] && result[aindex][index].data[result[aindex][index].data.length-3].value[0] === 11011 ?
                                                    <AllInclusiveIcon />
                                                    :
                                                    <>
                                                    {result[aindex] && result[aindex][index].data[result[aindex][index].data.length-3].value[0]}
                                                    <Percent />
                                                    </>
                                                }
                                                </div>
                                            </div>

                                        </div>

                                         
                                        <div className={Style.highlightChild}>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    STD Cost NPV
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${result[aindex] && result[aindex][index].data[result[aindex][index].data.length-2].value[0] < 0 && "danger"}
                                                `}>
                                                <>
                                                <CurrencyIconRenderer type={myCurrency} />
                                                </>
                                                {
                                                        result[aindex] &&
                                                        <>
                                                        {
                                                            (myCurrency === "INR") ?
                                                            (NumberFormat.toINDstandard(result[aindex][index].data[result[aindex][index].data.length-2].value[0].toFixed(2)) || 0)
                                                            :
                                                            (NumberFormat.toUSstandard(result[aindex][index].data[result[aindex][index].data.length-2].value[0].toFixed(2)) || 0 )

                                                        }
                                                   
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className={Style.highlightTile}>
                                                <div className={Style.highlightTleft}>
                                                    STD Cost IRR
                                                </div>
                                                <div className={`
                                                ${Style.highlightTright}
                                                ${(result[aindex] && result[aindex][index].data[result[aindex][index].data.length-1].value[0] < ((myresult[aindex][index].cashFlow.map(elem=> elem.irrRate).reduce((a,b)=> a+b) || 0)/myresult[aindex][index].cashFlow.length)  ? "danger" : "green")}`}>
                                                    
                                                {
                                                    result[aindex] && result[aindex][index].data[result[aindex][index].data.length-1].value[0] === 11011 ?
                                                    <AllInclusiveIcon />
                                                    :
                                                    <>
                                                    {result[aindex] && result[aindex][index].data[result[aindex][index].data.length-1].value[0]}
                                                    <Percent />
                                                    </>
                                                }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        elem.servicableYear < 1 &&
                                        <h3 style={{margin:"100px auto",textAlign:"center",width:"100%"}}>No Cashflow to show</h3>
                                    }
                              
                                    
                                    {
                                        elem.servicableYear > 1 &&
                                        <ScrollerWrapper>
                                            <table className={Style.table}>
                                                
                                                <tbody>
                                                <tr>
                                                    <th></th>
                                                    {
                                                        Array.from({length:(myresult[aindex][index].cashFlow.map(elem=> elem.tables.ds.length).reduce((a,b)=> a>b ? a : b) || 0)  }).map((elem,ind)=>(
                                                            <th key={`th${ind}`}>
                                                                <div className={Style.tableHeaderBox}>
                                                                    Year {ind}
                                                                </div>
                                                            </th>
                                                        ))
                                                    }
                                                
                                                </tr>
                                        
                                        
                                                {
                                                    elem.data.map((ielem,iind)=>(
                                                      <Fragment key={`${index}${iind}`}>
                                                         <tr>
                                                        <td>
                                                            <div className={Style.tableLeft}>
                                                                {ielem.name}
                                                            </div>
                                                        </td>
                                                        {
                                                            
                                                            ielem.value.map((iielem,iiind)=>(
                                                                <td key={`${index}${iind}${iiind}`}>
                                                                    {
                                                                        ((
                                                                            (iiind === 0 && iind <3) || 
                                                                            (iiind > elem.yearsRemaining && iind <3 ) || 
                                                                            (iiind === 1 && iind ===1) || 
                                                                            (iiind === 1 && iind ===2)
                                                                        )) ?
                                                                        <></>
                                                                        :
                                                                        <div className={Style.tableInpCnt}>
                                                                            {
                                                                                (iind > 0 && iind< 5) &&
                                                                                <Percent />
                                                                            }
                                                                            <input 
                                                                            type="text" 
                                                                            value={myCurrency === "INR" ? NumberFormat.toINDstandard(iielem)  : NumberFormat.toUSstandard(iielem) } 
                                                                            className={Style.tableInp} 
                                                                            onChange={(e)=> changeHandler(aindex,index,iind,iiind,NumberFormat.toNumber(e.target.value)) }
                                                                            />
                                                                            
                                                                        </div>
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                      </Fragment>
                                                    ))
                                                }
                                                {
                                                    result[aindex] && result[aindex][index]?.data.map((ielem,iind)=>(
                                                        <tr key={`res${index}${iind}`}>
                                                            <div className={Style.tableLeft}>
                                                                    {ielem.name}
                                                            </div>
                                                            {
                                                                ielem.value.map((iielem,iiind)=>(
                                                                    <td key={`res${index}${iind}${iiind}`}>
                                                                        {
                                                                            (
                                                                                ( (iiind === 0 && iind <= 26) ) ||
                                                                                (
                                                                                    (iiind > elem.yearsRemaining && iind !== 13 && iind !== 14 && iind !== 15 && iind !== 16 && iind !== 28 && iind !== 29)
                                                                                )
                                                                                
                                                                            ) ?
                                                                            <div className={Style.cashflowValCnt}>
                                                                            -   
                                                                            </div>
                                                                            :
                                                                            <>
                                                                            {
                                                                             
                                                                            <div className={`
                                                                                ${Style.cashflowValCnt}
                                                                                ${( (iind === 31 || iind == 33) && ((ielem.value[0] < ((myresult[aindex][index].cashFlow.map(elem=> elem.irrRate).reduce((a,b)=> a+b) || 0)/myresult[aindex][index].cashFlow.length) )  ? "danger" : ielem.value[0] != 11011 && "green"))}
                                                                                ${( ((iind === 30 || iind == 32)) && ielem.value[0] < 0 ) && "danger"}
                                                                            `}>
                                                                                {
                                                                                    (iiind === 0 && (iind === 31 || iind === 33)) ?
                                                                                        (iielem != 11011) &&
                                                                                        <Percent />
                                                                                    :
                                                                                    <>
                                                                                    <CurrencyIconRenderer type={myCurrency} />
                                                                                    </>
                                                                                }
                                                                            
                                                                            {
                                                                                (iiind === 0 && (iind === 31 || iind == 33) && iielem == 11011) ?
                                                                                    "NA"
                                                                                :
                                                                                <>
                                                                                {
                                                                                    (myCurrency === "INR") ?
                                                                                    (NumberFormat.toINDstandard(iielem.toFixed(2)) || 0)
                                                                                    :
                                                                                    (NumberFormat.toUSstandard(iielem.toFixed(2)) || 0 )
                                                                                    
                                                                                }
                                                                                </>
                                                                            }
                                                                            </div>
                                                                            }
                                                                            </>
                                                                        }
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                                
                                                </tbody>
                                    

                                            </table>
                                        </ScrollerWrapper>
                                    }
                                    
                                    

                            </div>
                        ))
                 
                    ))
                }
        </div>
    )
}

export default DeltaTableScreen;