import { CalculatorType } from "../@types/user";

class Calculator{

    getCalculatorfields({type}:{type:number}):CalculatorType{
        return [
            {
                name:"Job & Tool Specification",
                cmp:false,
                child:[
                    {
                        name:`${type === 0 ? "Project" : "Scenario"} Description`,
                        type:"text",
                        value:"",
                        error:false,
                        msg:[
                            `Input the program or platform name, part or product used and recognised within your organization, and desired evaluation process.`,
                            `Eg: “Chevy Corvette door trim panel Kaizen event.”`
                        ]
                    },
                    {
                        name:"Part no / Job no",
                        type:"text",
                        value:"",
                        error:false,
                        msg:[
                            `The internally recognized part/product number goes into this input field.`,
                            `Eg: “FD208-47KW”`
                        ],
                        required:true
                    },
                    {
                        name:"Number of Cavities/Sets",
                        type:"nestedNum",
                        value:"",
                        error:false,
                        msg:[
                            `In this field, we reference the total number of parts in the value-stream that creates a set and/or the number of total cavities in the primary tool.`,
                            `Eg1: Primary Mold “ABC” is a 4-cavity mold that produces all 4 door trim panels  (driver front, passenger front, rear left, rear right) that creates the entire ABC door trim panel value stream. In this example, we can evaluate anywhere between 1 to 4 cavities; however, to evaluate the entire 4-cavity value-stream, we would enter “4” into this field to evaluate all 4 door trim panels as a set.`,
                            `Eg2: Similar to example 1 (above) If a value-stream is made up of multiple primary purchased components to create a set, then you would enter the desired number of primary components you would want to evaluate in that set. For example, if there are 2 primary components that creates a value-stream set and you want to evaluate the entire value-stream, you would insert a “2” into this field.`
                        ],
                        required:true,
                        popupArrayName:"Cavity",
                        fields:[
                            {name:"Sell Price",type:"Cnumber",currency:true},
                            {name:"BOM Cost",type:"Cnumber",currency:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Are the cavities/sets symmetrical/identical?",
                        type:"bool",
                        value:"yes",
                        error:false,
                        msg:[
                            `In this field, if the cavities are symmetrical or mirror images, have the same selling price, have the same work content, and have the same BOM cost; then you can select “Yes”. Otherwise, select “No” where you will be directed to an input screen where you will fill out each parts’ individual sell price and BOM.`
                        ],
                        required:true,
                        disabled:true
                    }  
                ],
                pageErr:false
            },
            {
                name : "Sales, Volume and Material Information",
                cmp:false,
                child:[
                    {
                        name:"Average Sell Price Per Part($/part)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `If you entered “Yes” in the previous step, please input the common selling price for the identical cavities; otherwise, this field would be automatically calculated if you entered “No” after filling out the required information regarding each cavity.`
                        ],
                        required : true,
                        currency:true
                    },
                    {
                        name:"Average BOM Per Part ($/part)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `If you entered “Yes” in the previous step, please input the common BOM for the identical cavities; otherwise, this field would be automatically calculated if you entered “No” after filling out the required information regarding each cavity.`
                        ],
                        required : true,
                        currency:true
                    },
                    {
                        name:"Annual Sets per year",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This field enters the estimated number of Annual Shots/Sets that should be run to achieve the overall projected annual volume, for example, if the annual part volume is 500,000 units for a symmetrical 4-cavity tool, then the annual projected shots would be 125,000 shots or 500,000 parts divided by 4 cavities. However, if the cavities are not symmetrical (eg. 4-part door trim panel example) if the required annual door trim set volume is 200,000 sets then 200,000 shots must be run off the 4-cavity tool to meet overall production volume.`
                        ],
                        required:true
                    },
                    {
                        name:"Is there an upper Capacity Planning Volume limit?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:[
                            `In this field, only answer “Yes” if a contractual upper capacity limit agreement exists that once exceeded changes the commercial agreement terms and/or pricing. The default in this input field is “No”. If you answered “Yes”, please insert the upper capacity volume limit.`
                        ]
                    },
                    {
                        name:"Is there a lower Capacity Planning Volume limit?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:[
                            `In this field, only answer “Yes” if a contractual lower capacity limit agreement exists that once exceeded changes the commercial agreement terms and/or pricing. The default in this input field is “No”. If you answered “Yes”, please insert the lower capacity volume limit.`
                        ]
                    },                     
                    {
                        name:"Months Remaining in Job",
                        type:"num",
                        value:"60",
                        error:false,
                        msg:[
                            `The anticipated remaining time in months that the Job will continue under the negotiated contract. This time-period will also serve as the primary NPV time horizon.`
                        ],
                        required:true
                    },
                    {
                        name:"Weighted Average Cost of Capital %",
                        type:"num",
                        value:"10",
                        error:false,
                        msg:[
                            `Weighted average cost of capital (WACC) represents a company's average after-tax cost of capital from all sources, including common stock, preferred stock, bonds, and other forms of debt. As such, WACC is the average rate that a company expects to pay to finance its business.`
                        ],
                        percent:true
                    },
                    {
                        name:"Total Tax Rate %",
                        type:"num",
                        value:"35",
                        error:false,
                        msg:[
                            `Total tax rate measures the amount of taxes and mandatory contributions payable by businesses after accounting for allowable deductions and exemptions as a share of commercial profits.`
                        ],
                        required:true,
                        percent:true
                    },
                    {
                        name:"Enter current Enterprise profit or loss Amount.",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `If available, please enter the Enterprise Profit and/or Loss over the previous or trailing 12 months (TTM).`, 
                            `If the TTM is not available, please input the Enterprise Profit or Loss from the previous calendar year.`,
                            `If the profit or loss information is more than 12 months old, leave this field blank.`
                        ],
                        secDrop:{
                            list:[
                                {value:11,label:"Profit"},
                                {value:12,label:"Loss"}
                            ],
                            value:11
                        },
                        currency:true
                    },
                    {
                        name:"Do you want to include Inflation Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:[
                            `If you choose “No”, then in the output no inflationary effects will be considered; if “Yes”, put the most accurate inflation rate that will affect the cost of your enterprise over the next calendar year.`
                        ],
                        percent:true
                    },
                    {
                        name:"Do you have an IRR Hurdle Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:[
                            `The hurdle rate is the minimum rate of return on an investment that will offset its costs. The internal rate of return is the amount above the break-even point that an investment may earn. A favorable decision on a project can be expected only if the internal rate of return is equal to or above the hurdle rate. In the output fields, if this percentage is achieved, it will be highlighted in green; if it is not achieved, it will be highlighted in red.`
                        ],
                        percent:true
                    },
                    {
                        name:"Do you have an ATROS Hurdle Rate(%) ?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:[
                            `The ATROS Hurdle Rate is the minimum acceptable percentage of after-tax profit divided by the total sales of the evaluated project that the company will approve. For example, if the hurdle rate is 8% and a product with $1M annual sales achieves a total annual profit of  $60,000, then the ATROS would be only 6% and fail the hurdle rate. In this example, this ATROS result will be highlighted in red in the output fields. However, if the results met or exceeded 8%, it would be highlighted in green.`
                        ],
                        percent:true
                    },
                    {
                        name:"Scrap recovery sales",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is the periodic (weekly, monthly, yearly) scrap reclaim recovery value.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Beginning of fiscal year ? (Date & Month)",
                        type:"num",
                        value:"1",
                        error:false,
                        msg:[
                            ``
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Jan"},
                                {value:1,label:"Feb"},
                                {value:2,label:"Mar"},
                                {value:3,label:"Apr"},
                                {value:4,label:"May"},
                                {value:5,label:"Jun"},
                                {value:6,label:"Jul"},
                                {value:7,label:"Aug"},
                                {value:8,label:"Sep"},
                                {value:9,label:"Oct"},
                                {value:10,label:"Nov"},
                                {value:11,label:"Dec"}
                            ],
                            value:0
                        }
                    }
                ],
                pageErr:false
    
            },
            {
                name:"Machine and Operator Burden Summary",
                cmp:false,
                child:[
                    {
                        name:`Standard Cost Overhead Rate ($/hr)`,
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `The overhead rate, sometimes called the standard overhead rate, is the cost a business allocates to production to get a more complete picture of product and service costs. The overhead rate is calculated by adding indirect costs and then dividing those costs by a specific measurement.`,
                            `Please check to ensure whether the Standard Cost Overhead Rate does or does not include machine burden rate and/or SG&A rates. If the Standard Cost Overhead Rate does include machine burden and/or SG&A, there is no need to fill out the two fields below. However, if those costs are not included in the Standard Cose Overhead Rate, you must also include a value for the machine burden rate and/or SG&A rates.`
                        ],
                        required:true,
                        currency:true
                    },
                    {
                        name:"Machine Burden Rate ($/hr)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `In manufacturing, machine burden is often applied to inventory cost in addition to Standard Overhead Costs to arrive at an estimated Total Overhead cost for an activity center, value-stream, entity or even enterprise level organization. Machine Burden Rate is often referred to as factory or manufacturing overhead directly associated with capital equipment or machine replacement costs, which can also include machine maintenance, critical spare cost and machine related utilities consumption costs. This field should be filled if it is not included in the Standard Cost Overhead Rate.`
                        ],
                        currency:true,
                        required:true
                    },
                    {
                        name:"SG&A, ED&T, Launch, R&D, Marketing and Advertising ($/hr)",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Selling, general, and administrative expenses (SG&A) include all non-production expenses for a reporting period. Examples of these expenses are marketing, advertising, rent, and utilities. This line item includes nearly all business costs that aren't directly attributable to making a product or performing a service. This field should be filled if it is not included in the Standard Cost Overhead Rate.`
                        ],
                        currency:true
                    }
                ],
                pageErr:false
            },
            {
                name:"STD Direct Labor, Break Relief, & Wage Operator Summary",
                cmp:false,
                child:[
                    {
                        name:"Numbers of operators per shift/job",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Direct Labour is any worker that helps move a product or service from a lower state of raw materials and WIP closer to a state of a finished good or a completed service. In this field, please record the total number of direct labour for the activity, process, value-stream or enterprise being evaluated. In this field, do not include break relief operators as that will be calculated in the next field.`
                        ],
                        required:true
                    },
                    {
                        name:"Is this a continuous operation",
                        type:"bool",
                        value:"yes",
                        error:false,
                        msg:[
                            `If you answered yes, this operation is a continuous (around the clock) operations requiring break relief operators to break primary operators during lunch times and breaks.`,
                            ` If you answered no, the operators in the operation being evaluated all go to lunch and breaks simultaneously and during lunch and breaks, the operations shuts down.`
                        ]
                    },
                    {
                        name:"Break Relief Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `In this field, please enter number of break relief operators divided by the total primary operators in the operation being evaluated.`,
                            `E.g.: If a break relief operator relieves 4 primary operators during the work shift, the percentage would be ¼ or 25%; whereas, if a break relief operator relieves 4 primary operators during the work shift, the percentage would be 1/5 or 20%.`
                        ],
                        required:true,
                        percent:true
                    },
                    {
                        name:"Average Operator Base Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is simply the average direct labour hourly base wage for the operation being evaluated.`
                        ],
                        currency:true,
                        required:true
                    },
                    {
                        name:"Hourly Employee Benefit Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This would be the average benefit percentage to include healthcare, vacation & retirement  benefits plus deducted social security, FICA, medicare & medicaid and/or any other benefit included above the base wage for the direct laborer.`,
                            `The benefit ratio is the benefits accrued over a standard 40-hour work week or whatever the recognised average work week is for the direct labor pool.`,
                            `E.g.: If the total weekly benefits for any direct labor is $280 and the standard work week is 40-hours, then the benefits per hour would be $280/40 hours or $7/hour: and if, the hourly base rate wage is $20/hour, then benefit percentage will be ($7/hour) / ($20/hour) or 35%. Thus, in this scenario you would enter 35%.`
                        ],
                        percent:true
                    },
                    {
                        name:"Average Operator Fully Fringed Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This field is auto-calculated, and the default percentage is 35%. This will be a result of the percentage benefit entered in the field above and is simply the base wage plus the percentage benefit times the base wage.`
                        ],
                        currency:true,
                        required:true
                    },
                    {
                        name:"Overtime Premium Percentage",
                        type:"popup",
                        value:"",
                        error:false,
                        msg:[
                            `This indicates the overtime premium percentage once you have exceeded the weekly standard work hours. It will record the overtime percentages in addition to the base wage for Saturdays, Sundays and Holidays. These percentages being entered represents the overtime premium in addition to the base wage, e.g.: if the Saturday overtime percentage is 50% premium and the base wage is $20/hour, then the overtime wage will be ($20/hour) + ($20/hour * 50%), or $30/hour.`
                        ],
                        data:[
                            {
                                name:"Saturdays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Sundays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Holidays",
                                value:"",
                                percent:true
                            }
                        ],
                        required:true

                    },
                    {
                        name:"Absenteeism & Turnover",
                        type:"oneScreenPopup",
                        value:"",
                        error:false,
                        msg:[
                            `This records your average weekly planned and unplanned absenteeism and also your average weekly turnover as a percent of the total workforce within each workforce category to include direct labor, variable indirect labor, and fixed indirect labor.`,
                            `E.g.: If the company has a total direct labor pool of 300 workers and 10 are taking planned vacation and another 5 are unplanned absences, you would enter 3.33% into the planned absenteeism field which is equal to 10/300 and you would enter 1.67% into the unplanned absenteeism field which is equal to 5/300.`
                        ],
                        dataArr:[
                            {
                                columnName:"Absenteeism",
                                data:[
                                    {
                                        name:"Planned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    },
                                    {
                                        name:"Unplanned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            },
                            {
                                columnName:"Turnover",
                                data:[
                                    {
                                        name:"Weekly turnover rate",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            }
                        ],
                        required:true

                    }
                ],
                pageErr:false
            },
            {
                name:"ABC Direct Labor, Break Relief, & Wage Operator Summary",
                cmp:false,
                child:[
                    {
                        name:"Numbers of operators per shift/job",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Direct Labour is any worker that helps move a product or service from a lower state of raw materials and WIP closer to a state of a finished good or a completed service. In this field, please record the total number of direct labour for the activity, process, value-stream or enterprise being evaluated. In this field, do not include break relief operators as that will be calculated in the next field.`
                        ],
                        required:true
                    },
                    {
                        name:"Is this a continuous operation",
                        type:"bool",
                        value:"yes",
                        error:false,
                        msg:[
                            `If you answered yes, this operation is a continuous (around the clock) operations requiring break relief operators to break primary operators during lunch times and breaks.`,
                            ` If you answered no, the operators in the operation being evaluated all go to lunch and breaks simultaneously and during lunch and breaks, the operations shuts down.`
                        ]
                    },
                    {
                        name:"Break Relief Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `In this field, please enter number of break relief operators divided by the total primary operators in the operation being evaluated.`,
                            `E.g.: If a break relief operator relieves 4 primary operators during the work shift, the percentage would be ¼ or 25%; whereas, if a break relief operator relieves 4 primary operators during the work shift, the percentage would be 1/5 or 20%.`
                        ],
                        required:true,
                        percent:true
                    },
                    {
                        name:"Average Operator Base Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is simply the average direct labour hourly base wage for the operation being evaluated.`
                        ],
                        currency:true,
                        required:true
                    },
                    {
                        name:"Hourly Employee Benefit Ratio %",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This would be the average benefit percentage to include healthcare, vacation & retirement  benefits plus deducted social security, FICA, medicare & medicaid and/or any other benefit included above the base wage for the direct laborer.`,
                            `The benefit ratio is the benefits accrued over a standard 40-hour work week or whatever the recognised average work week is for the direct labor pool.`,
                            `E.g.: If the total weekly benefits for any direct labor is $280 and the standard work week is 40-hours, then the benefits per hour would be $280/40 hours or $7/hour: and if, the hourly base rate wage is $20/hour, then benefit percentage will be ($7/hour) / ($20/hour) or 35%. Thus, in this scenario you would enter 35%.`
                        ],
                        percent:true
                    },
                    {
                        name:"Average Operator Fully Fringed Wage $/hr",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This field is auto-calculated, and the default percentage is 35%. This will be a result of the percentage benefit entered in the field above and is simply the base wage plus the percentage benefit times the base wage.`
                        ],
                        currency:true,
                        required:true
                    },
                    {
                        name:"Overtime Premium Percentage",
                        type:"popup",
                        value:"",
                        error:false,
                        msg:[
                            `This indicates the overtime premium percentage once you have exceeded the weekly standard work hours. It will record the overtime percentages in addition to the base wage for Saturdays, Sundays and Holidays. These percentages being entered represents the overtime premium in addition to the base wage, e.g.: if the Saturday overtime percentage is 50% premium and the base wage is $20/hour, then the overtime wage will be ($20/hour) + ($20/hour * 50%), or $30/hour.`
                        ],
                        data:[
                            {
                                name:"Saturdays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Sundays",
                                value:"",
                                percent:true
                            },
                            {
                                name:"Holidays",
                                value:"",
                                percent:true
                            }
                        ],
                        required:true

                    },
                    {
                        name:"Absenteeism & Turnover",
                        type:"oneScreenPopup",
                        value:"",
                        error:false,
                        msg:[
                            `This records your average weekly planned and unplanned absenteeism and also your average weekly turnover as a percent of the total workforce within each workforce category to include direct labor, variable indirect labor, and fixed indirect labor.`,
                            `E.g.: If the company has a total direct labor pool of 300 workers and 10 are taking planned vacation and another 5 are unplanned absences, you would enter 3.33% into the planned absenteeism field which is equal to 10/300 and you would enter 1.67% into the unplanned absenteeism field which is equal to 5/300.`
                        ],
                        dataArr:[
                            {
                                columnName:"Absenteeism",
                                data:[
                                    {
                                        name:"Planned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    },
                                    {
                                        name:"Unplanned weekly absenteeism",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            },
                            {
                                columnName:"Turnover",
                                data:[
                                    {
                                        name:"Weekly turnover rate",
                                        type:"Cnumber2",
                                        value:"",
                                        perc:true,
                                        required:true
                                    }
                                ]
                            }
                        ],
                        required:true

                    }
                ],
                pageErr:false
            },
            {
                name:"Operations Overview",
                cmp:false,
                child:[
                    {
                        name:"Downtime",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Downtime, also known as Stop Time, is all time where the manufacturing process was intended to be running but was not due to Unplanned Stops (e.g., breakdowns), or Planned Stops (e.g., changeovers). Downtime is entered as a percentage of total downtime divided by total plant runtime.`
                        ]
                    },
                    {
                        name:"OEE",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `OEE is a metric which helps us understand how well a line/cell/machine is performing compared to design, by comparing actual production output to possible production output. Labor is not directly measured as part of OEE.`,
                            `Overall Equipment Effectiveness (OEE) is an industry standard, versatile measurement for production efficiency. It suits any industry or process. OEE is the result of three factors, Availability, Performance and Quality, which describe the six big losses. Together these factors form OEE (%) = Availability (%) X Performance (%) X Quality Rate (%).`
                        ],
                        required:true,
                        percent:true
                    },
                    {
                        name:"Scrap",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Scrap is a percentage of the total number of defective parts ran divided by the total number of parts ran. E.g.: The operation ran 100 total parts with 94 parts being good and 6 parts being scrap; therefore, the total scrap percentage equals 6% or 6 defective parts divided by 100 total parts ran.`
                        ],
                        percent:true
                    },
                    {
                        name:"Cycle Time",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is the standard quoted cycle time in seconds.`
                        ],
                        required:true
                    }
                ],
                pageErr:false
            },
            {
                name:"Operating Pattern",
                cmp:false,
                child:[
                    {
                        name:"How many shifts per day?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Please enter the total number of shifts per week. E.g.: If your organization three 8-hour shifts, 5 days a week, you would enter 3; however, if your organization runs four 12-hour shifts, 7 days per week, you would enter 4.`
                        ],
                        required:true
                    },
                    {
                        name:"How many hours per shift?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `How many planned hours per shift your organization runs.`
                        ],
                        required:true
                    },
                    {
                        name:"How many days per week?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `How many planned days per week your organization runs.`
                        ],
                        required:true
                    },
                    {
                        name:"How many operating days per year?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This field specifies the operational schedule, such as the number of operating days per year.`
                        ],
                        required:true
                    },
                    {
                        name:"Overtime Premium 1 above X yearly hours?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Based of your organization’s shifts’ operating patterns, please enter the number of hours during a calendar year where operations would move into overtime premium 1 then subsequently overtime premium 2.`,
                            `E.g.: If your organization runs 2 8-hour shifts, 5 days a week at 250 operating days per year, then each shift would operate (40-hours per week) * (250 days per year), or 2000 hours per shift before moving into OT Premium 1. Thus, 2 shifts at 2000 hours per year would equal 4000 hours per year before entering OT Premium 1. OT Premium 2 would be reached at operations exceeding 4000 hours per year as each operating day per shift per year equals 400 hours or 8 hours per day times 50 operating weeks per year.`
                        ]
                    },
                    {
                        name:"Overtime Premium 2 above Y yearly hours?",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Similar to OT Premium 1, OT Premium 2 is when the operating hours exceeds the base plus OT Premium 1 hours annually. This generally applies to holiday work and Sunday double time work.`,
                            `E.g.: If an organization is running 2 8-hour shifts, 250 operating days per year on a monday to friday schedule each operating shift per year equals 2000 hours per year or 8 hours per shift times 250 hours per year monday through friday. Therefore, each operating day equals 8 hours times 50 operating weeks per year, or 400 hours. In this example, base time per year equals 2000 hours shift per year times 2 shifts equalling 4000 base hours per year plus 800 OT Premium 1 hours per year resulting in OT Premium 2 for any hours exceeding 4800 hours per year.`
                        ]
                    }
    
                ],
                pageErr:false
            },
            {
                name:"Activity Based Variance",
                cmp:false,
                child:[
                    {
                        name:"How many Variable Indirect labour added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            `Any actual hourly Indirect resources added to satisfy incremental production requirements based off the scenario inputs.`
                        ],
                        popupName:"Enter the number of Variable Indirect labour added!",
                        popupArrayName:"Variable Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Variable Indirect labour removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            `Any actual hourly Indirect resources removed to satisfy incremental production requirements based off the scenario inputs.`
                        ],
                        popupName:"Enter the number of Variable Indirect labour removed!",
                        popupArrayName:"Variable Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Fixed Indirect labour added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            `Any actual salary Indirect resources added to satisfy incremental production requirements based off the scenario inputs.`
                        ],
                        popupName:"Enter the number of Fixed Indirect labour added!",
                        popupArrayName:"Fixed Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Fixed Indirect labour removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            `Any actual salary Indirect resources removed to satisfy incremental production requirements based off the scenario inputs.`
                        ],
                        popupName:"Enter the number of Fixed Indirect labour removed!",  
                        popupArrayName:"Fixed Indirect Labour",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Allocation for shared resources",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"Enter the number of shared resources!",  
                        popupArrayName:"Shared resource",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Plant SG&A resources added?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"Enter the number of SG&A resources added",  
                        popupArrayName:"G&A resource",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"How many Plant SG&A resources removed?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"Enter the number of SG&A resources removed",  
                        popupArrayName:"G&A resource",
                        fields:[
                            {name:"Name of the Position",type:"string",required:true},
                            {name:"Annual Fully Fringed Wage", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Utilities Increase/Reduction",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Any addition or reduction of overall utilities costs to satisfy incremental/reduced production requirements based off the scenario inputs.`
                        ],
                        currency:true
                    },
                    {
                        name:"Annual Plant Rent",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many different rents do you have?",
                        popupArrayName:"Rent type",
                        fields:[
                            {name:"Name of the Rent",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",percent:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Ongoing Annual Testing",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        currency:true
                    },
                    {
                        name:"Additional Activity-based Line Items required?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            `Any other non-accounted activity based costs to satisfy incremental/reduced production requirements based off the scenario inputs.`
                        ],
                        popupName:"Enter the number of Activity-based Line Items!",  
                        popupArrayName:"Activity-based Item",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Number of Other Variable Line Items?",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            `This section is reserved for items such as incremental/reduced like wholesale equipment leasebacks, forklift leases,  indirect purchases critical spares items increase or reduction, or any franchise fees etc.`
                        ],
                        popupName:"Enter the number of Other Variable Line Items!",
                        popupArrayName:"Variable Item",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Annual Cost", type:"Cnumber",currency:true,required:true}
                        ],
                        entries:[]
                    }
                ],
                pageErr:false
            },
            {
                name:"Enterprise Activity Based Variance",
                cmp:false,
                child:[
                    {
                        name:"Annual SG&A Allocation",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        currency:true
                    },
                    {
                        name:"Annual Marketing & Advertising Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        currency:true
                    },
                    {
                        name:"R&D",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Launch Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Design and Engineering Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"one time"},
                                {value:1,label:"annual"},
                            ],
                            value:0
                        },
                        currency:true
                    }
                ],
                pageErr:false
            },

            {
                name:"Investment",
                cmp:false,
                child:[
                    {
                        name:"Project Investment",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Any Investment directly associated with the project’s scenario that can be expensed within the same calendar year. E.g.: Consulting or professional fees, legal fees, restructuring fees, etc.`
                        ],
                        currency:true
                    },
                    {
                        name:"Capital Investment",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            `Records any incremental new or used capital assets that according to GAAP must adhere to a predetermined depreciation schedule. E.g.: Major presses, equipments, robots, assembly lines, quality testers, etc.`
                        ],
                        popupName:"How many equipments?",
                        popupArrayName:"Equipment",
                        fields:[
                            {name:"Description",type:"string",required:true},
                            {name:"Depreciation schedule",type:"Cnumber",required:true},
                            {name:"Cost", type:"Cnumber",currency:true,required:true},
                            {name:"Salvage Value", type:"Cnumber",currency:true},
                            {name:"What year in the life we will salvage", type:"Cnumber"}
                        ],
                        entries:[]
                    },
                    {
                        name:"Installation Rigging Expenses",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is simply fully expensible installation and/or rigging fees associated with capital equipments.`
                        ],
                        currency:true
                    },
                    {
                        name:"Any other Investment Expense",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is any other investment expense not captured in the interviewed process.`
                        ],
                        currency:true
                    }
                ],
                pageErr:false
            },
            
            {
                name:"Off Standard Costing",
                cmp:false,
                child:[
                    {
                        name:"Outbound Premium Freight ",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is total outbound premium freight cost paid by the organization logged either weekly, monthly or annually.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Inbound Premium Freight",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is total inbound premium freight cost paid by the organization logged either weekly, monthly or annually.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Quality Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `This is total quality charges cost paid by the organization logged either weekly, monthly or annually. This charges include QRMA or any quality related costs due to quality rejects or quality related issues.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Third Party Sorting Charges GP-12",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `These includes all customer directed internal or external sorting costs paid by the organization logged either weekly, monthly or annually.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Internal Sorting Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `All sorting and inspection costs internally directed by the organization or paid for the organization logged either weekly, monthly or annually.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Offline Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `These are charge backed by the customers for creating a delay in customer production due to short shipments and/or quality defects created by the organization that create a situation where the customer is unable to manufacture complete units on time and also requires retro-fitting of the late or defective parts provided by the organization logged either weekly, monthly or annually.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Shut down Charges",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Shut down charges are severe charges levied against the organization from the customer where the organization’s inability to ship on time or ship quality parts shuts down the production operation of the customer paid by the organization logged either weekly, monthly or annually.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Any other Off standard Cost",
                        type:"num",
                        value:"",
                        error:false,
                        msg:[
                            `Any other off standard cost not included in this list paid by the organization logged either weekly, monthly or annually.`
                        ],
                        secDrop:{
                            list:[
                                {value:0,label:"Weekly"},
                                {value:1,label:"Monthly"},
                                {value:2,label:"Yearly"}
                            ],
                            value:0
                        },
                        currency:true
                    },
                    {
                        name:"Are you rolling Standard Overhead Costs?",
                        type:"decisive",
                        value:"",
                        error:false,
                        msg:[
                            `Are you maintaining and updating your standard overhead costs every year?`
                        ],
                        percent:true
                    },
                ],
                pageErr:false
            },
            {
                name:"Financing and Loan",
                cmp:false,
                child:[
                    {
                        name:"Senior Debt Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many Senior Debt Loans?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Name of the Loan",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Scenario", type:"Cnumber",required:true,percent:true},
                            {name:"Interest Rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments/year", type:"Cnumber",required:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Equipment Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many Equipment Loans?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Loan", type:"Cnumber",required:true,percent:true},
                            {name:"Interest rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments per year", type:"Cnumber",required:true,}
                        ],
                        entries:[]
                    },
                    {
                        name:"Mezzanine Lending",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many Mezzanine Lendings?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Loan", type:"Cnumber",required:true,percent:true},
                            {name:"Interest rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments per year", type:"Cnumber",required:true,}
                        ],
                        entries:[]
                    },
                    {
                        name:"Micro Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many Micro Loans?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Loan", type:"Cnumber",required:true,percent:true},
                            {name:"Interest rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments per year", type:"Cnumber",required:true,}
                        ],
                        entries:[]
                    },
                    {
                        name:"Personal Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many Personal Loans?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Loan", type:"Cnumber",required:true,percent:true},
                            {name:"Interest rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments per year", type:"Cnumber",required:true,}
                        ],
                        entries:[]
                    },
                    {
                        name:"Real Estate Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many Real Estate Loans?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Loan", type:"Cnumber",required:true,percent:true},
                            {name:"Interest rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments per year", type:"Cnumber",required:true,}
                        ],
                        entries:[]
                    },
                    {
                        name:"SBA Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many SBA Loans?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Total loan amount",type:"Cnumber",required:true,currency:true},
                            {name:"Loan start  date",type:"date",required:true},
                            {name:"% Allocation to Loan", type:"Cnumber",required:true,percent:true},
                            {name:"Interest rate ", type:"Cnumber",required:true,percent:true},
                            {name:"Loan duration in years", type:"Cnumber",required:true},
                            {name:"Payments per year", type:"Cnumber",required:true,}
                        ],
                        entries:[]
                    },
                    {
                        name:"Factoring Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many Factored Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Factored Customer",type:"string",required:true},
                            {name:"Annual Invoiced Amount",type:"Cnumber",required:true,currency:true},
                            {name:"Annual Factoring Receipts", type:"Cnumber",required:true,currency:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Line of credit Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many LOC Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Credit Limit",type:"Cnumber",required:true,currency:true},
                            {name:"Average Annual Amount Borrowed",type:"Cnumber",required:true,currency:true},
                            {name:"Annual Interest rate ", type:"Cnumber",required:true,percent:true}                 
                        ],
                        entries:[]
                    },
                    {
                        name:"Simple Interest Loan",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many SI Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Principal Amount",type:"Cnumber",required:true,currency:true},
                            {name:"Annual Interest rate ", type:"Cnumber",required:true,percent:true}
                        ],
                        entries:[]
                    },
                    {
                        name:"Inventory Reduction/Addition",
                        type:"DoublePopup",
                        value:"",
                        error:false,
                        msg:[
                            ``
                        ],
                        popupName:"How many LOC Loan?",
                        popupArrayName:"Loan Number",
                        fields:[
                            {name:"Loan name",type:"string",required:true},
                            {name:"Credit Limit",type:"Cnumber",required:true,currency:true},
                            {name:"Average Annual Amount Borrowed",type:"Cnumber",required:true,currency:true},
                            {name:"WACC/Interest Rate", type:"Cnumber",required:true,percent:true}                 
                        ],
                        entries:[]
                    },
                ],
                pageErr:false
            }
        ]
    }

    createNewCopyOfCalcFields(val:CalculatorType){
        let x = val.map(elem=> ({...elem,child:[...elem.child].map(el=> ({...el, entries:(el.entries ? [...el.entries!!]:[]).map(e=> ([...e].map(e2=> ({...e2})))), secDrop:el.secDrop && {...el.secDrop}   }))}));
        return x;
    }
    getRealTime(){
        let date = new Date().toDateString();
        let time = new Date().toLocaleTimeString();

        return `${date}, ${time}`;
    }
}

export default new Calculator;